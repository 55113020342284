import * as React from "react"
import { Helmet } from "react-helmet"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const imageStyles = {
  maxHeight: '500px'
}

const mainWrapper = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center'
}

const headingStyle = {
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Home Page</title>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bottomline Hospitality</title>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
      </Helmet>
      
      <div style={mainWrapper}>
        <div>
          <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/bottomlinehospitality/splash.jpg" style={imageStyles} />
        </div>
        <div>
          <h2 className="mb-4">Bottomline Hospitality</h2>
          <h5 className="text-secondary">Email Me:</h5>
          <p>
            <a href="mailto:buymybottom@gmail.com">buymybottom@gmail.com</a>
          </p>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
